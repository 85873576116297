import DrSectionHeader from '@/doc-consultation/presentation/atoms/DrSectionHeader';
import Line from '@/labtest/presentation/atoms/Line';

import { IG_DrAppointmentDetails } from '@/services/doc-consultation/apis/doc-consultation-apis/types/doc-consultation/doctor-portal/type.getDrAppointmentDetails';
import { IG_DoctorConsultationDetails } from '@/services/doc-consultation/apis/doc-consultation-apis/types/doc-consultation/type.getDocConsultationDetails';
import { isEmptyArray } from '@/services/lab-test/utils/cmnHelper';
import { MdRemoveRedEye } from 'react-icons/md';
import styles from './ConsultPatientProblemsCard.module.scss';

interface I_Props {
	page?: string;
	data: Partial<IG_DoctorConsultationDetails> | Partial<IG_DrAppointmentDetails>;
	isEdit?: boolean;
	setIsEdit?: (value: boolean) => void;
	setIsSaveInfo?: (value: boolean) => void;
	type?: 'doctor' | 'patient';
}

const ConsultPatientProblemsCard = ({ page, data, isEdit, setIsEdit, setIsSaveInfo, type }: I_Props) => {
	return (
		<>
			<div className={`${styles.consultPatientProblemsCard} ${styles[page]}`}>
				<DrSectionHeader
					text='Problem Details'
					icon={type === 'doctor' ? null : <span className='ltbody_lg__semibold primaryBase'>Edit</span>}
					onclick={() => {
						setIsEdit(true);
						setIsSaveInfo && setIsSaveInfo(false);
					}}
				/>

				<div className={styles.consultPatientProblemsCard_body}>
					{/* Main Reasons */}
					<div className={styles.main_reasons_container}>
						<div className='ltbody_lg__semibold ltGreyscale700 mb-12'>Main Reason:</div>
						<p className='ltbody_lg__regular ltGreyscale600'>{data?.consultationReason || 'Not Provided'}</p>
					</div>

					<Line dashed='dashed' />

					{/* Patient Problem */}
					<div className={styles.patient_problem_container}>
						<div className='ltbody_lg__semibold ltGreyscale700 mb-12'>Patient Problem:</div>
						<p className='ltbody_lg__regular ltGreyscale600'>{data?.additionalNotes || 'Not Provided'}</p>
					</div>

					<Line dashed='dashed' />

					{/* Attachment */}

					<div className={styles.view_attachments}>
						<p className='ltbody_lg__semibold ltGreyscale900'>Attachment:</p>
						<div className='flex-column gap-12'>
							{!isEmptyArray(data?.documents) ? (
								data?.documents?.map((document, index) => (
									<div className={styles.attachment} key={index}>
										<p className='ltbody_lg__regular primaryBase'>{document?.documentName}</p>
										<div className='flex gap-15'>
											<span>
												<MdRemoveRedEye size={24} color='var(--ltGreyscale600)' />
											</span>
										</div>
									</div>
								))
							) : (
								<p className='ltbody_lg__regular ltGreyscale600'>Not Provided</p>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ConsultPatientProblemsCard;
