'use client';

import DrSectionHeader from '@/doc-consultation/presentation/atoms/DrSectionHeader';
import ConsultReasonForm from '@/doc-consultation/presentation/molecules/PatientAdditionalInfoForm/ConsultReasonForm';
import Button from '@/labtest/presentation/atoms/Button';

import { updateDocConsultationAdditionalInfo } from '@/services/doc-consultation/apis/doc-consultation-apis/doc-consultation';
import { IG_DoctorConsultationDetails } from '@/services/doc-consultation/apis/doc-consultation-apis/types/doc-consultation/type.getDocConsultationDetails';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { toast } from 'react-toastify';

import styles from './EditPatientConsultationReason.module.scss';

interface I_Props {
	consultation?: Partial<IG_DoctorConsultationDetails>;
	consultationId?: string;
	handleGetConsultationDetails?: () => void;
	isEdit?: boolean;
	setIsEdit?: React.Dispatch<React.SetStateAction<boolean>>;
	setIsSaveInfo?: React.Dispatch<React.SetStateAction<boolean>>;
	page?: string;
}

export interface I_ConsultationAdditionalInfoState {
	consultationReason: string;
	additionalNotes: string;
	files: File[] | any;
	removeDocumentUqids: File[] | any;
}

const EditPatientConsultationReason = forwardRef(
	(
		{ consultation, consultationId, handleGetConsultationDetails, isEdit, setIsEdit, setIsSaveInfo, page }: I_Props,
		ref
	) => {
		const [consultationAdditionalInfo, setConsultationAdditionalInfo] = useState<I_ConsultationAdditionalInfoState>({
			consultationReason: '',
			additionalNotes: '',
			files: [],
			removeDocumentUqids: []
		});

		const handleUpdateDocConsultationAdditionalInfo = async () => {
			const formData = new FormData();

			if (consultationAdditionalInfo?.consultationReason) {
				formData.append('consultationReason', consultationAdditionalInfo?.consultationReason);
			}
			if (consultationAdditionalInfo?.additionalNotes) {
				formData.append('additionalNotes', consultationAdditionalInfo?.additionalNotes);
			}
			if (consultationAdditionalInfo?.files?.length > 0) {
				for (let i = 0; i < consultationAdditionalInfo?.files.length; i++) {
					formData.append('files', consultationAdditionalInfo?.files[i]);
				}
			}
			if (consultationAdditionalInfo?.removeDocumentUqids?.length > 0) {
				for (let i = 0; i < consultationAdditionalInfo?.removeDocumentUqids?.length; i++) {
					formData.append('removeDocumentUqids[]', consultationAdditionalInfo?.removeDocumentUqids[i]);
				}
				// formData.append('removeDocumentUqids[]', consultationAdditionalInfo?.removeDocumentUqids);
			}

			if (consultationId) {
				const res = await updateDocConsultationAdditionalInfo(consultationId, formData);
				if (res?.success) {
					toast.success(res?.message);
					handleGetConsultationDetails && handleGetConsultationDetails();
					setIsEdit(false);
					setIsSaveInfo && setIsSaveInfo(true);
				}
			}
		};

		useImperativeHandle(ref, () => ({
			triggerSave: handleUpdateDocConsultationAdditionalInfo
		}));

		return (
			<div className={styles.editPatientConsultReason}>
				<DrSectionHeader text='Add more details for better treatment' />
				<div className={styles.constant_reason}>
					<ConsultReasonForm
						consultation={consultation}
						consultationAdditionalInfo={consultationAdditionalInfo}
						setConsultationAdditionalInfo={setConsultationAdditionalInfo}
						isEdit={isEdit}
						page={page}
					/>
					{page !== 'order_success_modal' && (
						<div className='flex gap-20'>
							<Button variant='bgWhiteGrey300' color='clGrey900' fullWidth onClick={() => setIsEdit(false)}>
								Cancel
							</Button>
							<Button fullWidth onClick={() => handleUpdateDocConsultationAdditionalInfo()}>
								Save Changes
							</Button>
						</div>
					)}
				</div>
			</div>
		);
	}
);

EditPatientConsultationReason.displayName = 'EditPatientConsultationReason';

export default EditPatientConsultationReason;
