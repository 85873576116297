import { OnlinePaymentMethods } from '@labServices/utils/data';
import { FaCircleCheck } from 'react-icons/fa6';

import Image from 'next/image';

import styles from './PaymentMethodInfo.module.scss';

const PaymentMethodInformation = () => {
	return (
		<>
			<div className={styles.paymentMethodInfo}>
				<div className={styles.paymentMethodInfo_header}>
					<h5 className='lth5_bold ltGreyscale900'>Pay now to complete your booking</h5>
				</div>

				<div className={styles.paymentMethodInfo_body}>
					<div className={styles.onlinePayment}>
						<FaCircleCheck size={24} color='var(--ltPrimary)' />
						<span className='ltbody_lg__medium ltGreyscale900'>Pay Online</span>
					</div>
					<div className={styles.paymentMethods}>
						{OnlinePaymentMethods?.map((item, i) => (
							<div
								key={i}
								className={styles.checkout_paymethod_img}
								// onClick={() => handlePaymentmetyhodSelect('', 'online')}
							>
								<Image
									width={100}
									height={45}
									src={item.name}
									alt='paymentMethod'
									unoptimized
									style={{
										width: '100%',
										height: '100%',
										objectFit: 'contain',
										objectPosition: 'center'
									}}
								/>
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	);
};

export default PaymentMethodInformation;
