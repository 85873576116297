import { BiSolidOffer } from 'react-icons/bi';

import styles from './CouponCodeInput.module.scss';

const CouponCodeInput = ({
	couponCode,
	setCouponCode,
	onClickApply,
	onClickReset,
	isCouponCodeApplied,
	componentType,
	applyButtonText = 'Apply'
}) => {
	return (
		<div className={`${styles.container} ${componentType === 'drc' ? styles.dr_container : ''}`}>
			<div className={styles.iconContainer}>
				<BiSolidOffer size={20} className={styles.icon} />
			</div>
			<input
				className={styles.input}
				type='text'
				placeholder='Enter Coupon Code'
				value={couponCode}
				disabled={isCouponCodeApplied}
				onChange={(e) => setCouponCode(e.target.value)}
			/>
			<button
				className={`${styles.button} t_nowrap`}
				disabled={(applyButtonText === 'Apply' || applyButtonText === 'Apply Now') && !couponCode}
				style={{
					backgroundColor: applyButtonText === 'Remove' && 'red'
				}}
				onClick={
					applyButtonText === 'Apply' || applyButtonText === 'Apply Now'
						? () => onClickApply(couponCode)
						: onClickReset
				}>
				{applyButtonText}
			</button>
		</div>
	);
};

export default CouponCodeInput;
