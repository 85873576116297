import { v1_upload_prescription_icon_bg } from '@/services/doc-consultation/utils/svg';
import { MdClose } from 'react-icons/md';

import BookforItem from '@labtest/presentation/atoms/BookforItem';
import Dropdown from '@labtest/presentation/atoms/Dropdown';
import Input from '@labtest/presentation/atoms/Input';
import Label from '@labtest/presentation/atoms/Label';
import Popover from '@labtest/presentation/atoms/Popover';
import { MdRemoveRedEye } from 'react-icons/md';

import { I_ConsultationAdditionalInfoState } from '@/doc-consultation/presentation/organisms/MyConsultationDetails/EditPatientConsultationReason';
import { getDocConsultationReasons } from '@/services/doc-consultation/apis/doc-consultation-apis/doc-consultation';
import { IG_DoctorConsultationDetails } from '@/services/doc-consultation/apis/doc-consultation-apis/types/doc-consultation/type.getDocConsultationDetails';
import { IG_DocConsultationReasons } from '@/services/doc-consultation/apis/doc-consultation-apis/types/doc-consultation/type.getDocConsultationReasons';
import useToggle from '@/services/lab-test/hooks/use-toggle';
import { isEmptyArray, isLoggedInUser } from '@/services/lab-test/utils/cmnHelper';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styles from './ConsultReasonForm.module.scss';

interface I_Props {
	consultation: Partial<IG_DoctorConsultationDetails>;
	consultationAdditionalInfo: Partial<I_ConsultationAdditionalInfoState>;
	setConsultationAdditionalInfo: React.Dispatch<React.SetStateAction<Partial<I_ConsultationAdditionalInfoState>>>;
	isEdit: boolean;
	page?: string;
}

const ConsultReasonForm = ({
	consultation,
	consultationAdditionalInfo,
	setConsultationAdditionalInfo,
	isEdit,
	page
}: I_Props) => {
	const user = isLoggedInUser();
	const [openConsultationReasons, handleOpenConsultationReasons, handleCloseConsultationReasons] = useToggle();
	const [reasons, setReasons] = useState<Partial<IG_DocConsultationReasons>[]>([]);
	const router = useRouter();
	const [tempAdditionalInfo, setTempAdditionalInfo] = useState<Partial<I_ConsultationAdditionalInfoState>>({
		consultationReason: '',
		additionalNotes: '',
		files: [],
		removeDocumentUqids: []
	});

	console.log('AdditionalInfo:', {
		consultationAdditionalInfo: {
			files: consultationAdditionalInfo?.files,
			removeDocumentUqids: consultationAdditionalInfo?.removeDocumentUqids
		},
		tempAdditionalInfo: {
			files: tempAdditionalInfo?.files,
			removeDocumentUqids: tempAdditionalInfo?.removeDocumentUqids
		}
	});

	const handleGetDocConsultationReasons = async () => {
		const res = await getDocConsultationReasons();
		if (res?.success) {
			setReasons((prev) => [...(res?.data as Partial<IG_DocConsultationReasons>[])]);
		}
	};

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		console.log('FilesAdd:', e.target.files);
		const files = e.target.files;
		if (files.length > 0) {
			const fileArray = Array.from(files);
			setConsultationAdditionalInfo((prev) => ({ ...prev, files: [...(prev.files || []), ...fileArray] }));
			setTempAdditionalInfo((prev) => ({ ...prev, files: [...(prev.files || []), ...fileArray] }));
		} else {
			setConsultationAdditionalInfo((prev) => ({ ...prev, files: [] }));
			setTempAdditionalInfo((prev) => ({ ...prev, files: [] }));
		}
	};

	const handleFileDelete = (fileId?: string, fileName?: string) => {
		if (fileId) {
			setTempAdditionalInfo((prev) => ({
				...prev,
				files: prev.files.filter((file) => file?.id !== fileId),
				removeDocumentUqids: [...(prev.removeDocumentUqids?.filter((doc) => doc !== fileId) || [])]
			}));

			setConsultationAdditionalInfo((prev) => ({
				...prev,
				files: prev.files.filter((file) => file?.id !== fileId),
				removeDocumentUqids: [...(prev.removeDocumentUqids || []), fileId]
			}));
		} else {
			setTempAdditionalInfo((prev) => ({
				...prev,
				files: prev.files.filter((file) => file?.name !== fileName),
				removeDocumentUqids: []
			}));

			setConsultationAdditionalInfo((prev) => ({
				...prev,
				files: prev.files.filter((file) => file?.name !== fileName),
				removeDocumentUqids: []
			}));
		}
	};

	useEffect(() => {
		if (!user) {
			router.back();
			toast.error('Please login to continue');

			return;
		}
		handleGetDocConsultationReasons();
	}, []);

	useEffect(() => {
		if (consultation?.id) {
			setTempAdditionalInfo((prev) => ({
				consultationReason: consultation?.consultationReason,
				additionalNotes: consultation?.additionalNotes,
				removeDocumentUqids: consultation?.documents?.map((doc) => doc?.id) || [],
				files: consultation?.documents || []
			}));
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [consultation?.id, isEdit]);

	return (
		<div className='flex-column gap-20'>
			<div className='fullWidth'>
				<Label label='Reason to Consult' required />
				<Dropdown
					placeHolder={'Select main reason to visit'}
					def
					value={tempAdditionalInfo?.consultationReason}
					onClick={handleOpenConsultationReasons}
					fullWidth>
					<Popover
						open={openConsultationReasons}
						handleClose={handleCloseConsultationReasons}
						variant={'fullWidth'}>
						{!isEmptyArray(reasons) &&
							reasons?.map((reason) => {
								return (
									<BookforItem
										key={reason?.id}
										checked={consultationAdditionalInfo?.consultationReason === reason?.en}
										onClick={() => {
											setConsultationAdditionalInfo((prev) => ({ ...prev, consultationReason: reason?.en }));
											setTempAdditionalInfo((prev) => ({ ...prev, consultationReason: reason?.en }));
											handleCloseConsultationReasons();
										}}>
										<p className='capitalize'> {reason?.en}</p>
									</BookforItem>
								);
							})}
					</Popover>
				</Dropdown>
			</div>

			<div className='fullWidth'>
				<Input
					variant='outlined'
					placeholder='Describe the Problem'
					defaultValue={consultationAdditionalInfo?.additionalNotes}
					value={tempAdditionalInfo?.additionalNotes}
					onChange={(e) => {
						setConsultationAdditionalInfo((prev) => ({ ...prev, additionalNotes: e.target.value }));
						setTempAdditionalInfo((prev) => ({ ...prev, additionalNotes: e.target.value }));
					}}
					label='Briefly describe the Problem'
				/>
			</div>

			<div className='fullWidth mt-10'>
				<label
					htmlFor={page === 'order_success_modal' ? 'file_input_modal' : 'file_input'}
					className={styles.input_file_wrap}>
					<span>{v1_upload_prescription_icon_bg}</span>
					<div>
						<p className='ltbody_xlg__medium ltGreyscale900 mb-2'>Attach Reports and previous prescriptions</p>
						<p className='ltbody_lg__regular ltGreyscale600'>JPG, PNG, PDF (Maximum No. of attachments: 10)</p>
					</div>
				</label>
				<input
					id={page === 'order_success_modal' ? 'file_input_modal' : 'file_input'}
					accept='image/png, image/jpeg, image/jpg, application/pdf'
					type='file'
					multiple
					hidden
					onChange={handleFileChange}
				/>
			</div>

			<div className={styles.view_attachments}>
				<p className='ltbody_lg__semibold ltGreyscale900'>Attachment:</p>
				<div className='flex-column gap-15'>
					{/* {consultationAdditionalInfo?.files?.map((file, index) => (
						<div className={styles.attachment} key={index}>
							<p className='ltbody_lg__regular primaryBase'>{file?.name}</p>
							<div className='flex gap-15'>
								<span>
									<MdRemoveRedEye size={24} color='var(--ltGreyscale600)' />
								</span>
								<span>
									<MdClose
										size={24}
										color='var(--ltGreyscale600)'
										onClick={() => handleFileDelete(file?.id, file?.name)}
									/>
								</span>
							</div>
						</div>
					))} */}

					{tempAdditionalInfo?.files?.map((file, index) => (
						<div className={styles.attachment} key={index}>
							<p className='ltbody_lg__regular primaryBase'>{file?.documentName || file?.name}</p>
							<div className='flex gap-15'>
								<span>
									<MdRemoveRedEye size={24} color='var(--ltGreyscale600)' />
								</span>
								<span>
									<MdClose
										size={24}
										color='var(--ltGreyscale600)'
										onClick={() => {
											handleFileDelete(file?.id, file?.documentName || file?.name);
										}}
									/>
								</span>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default ConsultReasonForm;
