'use client';

import { AutoGraph, MarkEmailRead } from '@labServices/utils/svg';
import { useRouter } from 'next/navigation';

import { IG_DoctorConsultationPaymentInfo } from '@/services/doc-consultation/apis/doc-consultation-apis/types/doc-consultation/type.getDocConsultationPaymentInfo';
import Image from 'next/image';
import styles from './DocConsultationOrderSuccess.module.scss';

interface I_Props {
	data: Partial<IG_DoctorConsultationPaymentInfo>;
	closeOrderSuccessModal: () => void;
}

const DocConsultationOrderSuccess = ({ data, closeOrderSuccessModal }: I_Props) => {
	const { id: consultationId, consultationNumber, doctor } = data;
	const router = useRouter();

	const handlerViewOrderDetails = () => {
		closeOrderSuccessModal();
		router.push(`/account/doctorConsultation/my-consultations/${consultationId}`);
	};
	const handlerCreateNewOrder = () => {
		closeOrderSuccessModal();

		router.push('/doctor-consultation');
	};

	return (
		<>
			<div className={styles.order_details_container}>
				<div className={styles.order_id}>
					<div>
						<AutoGraph />
					</div>
					<div className={styles.order_id_info}>
						<div>Consultation ID</div>
						<div>#{consultationNumber}</div>
					</div>
				</div>
				<div className={styles.order_item}>
					<div className={styles.image}>
						<Image
							src={
								'https://images.theconversation.com/files/304957/original/file-20191203-66986-im7o5.jpg?ixlib=rb-4.1.0&q=45&auto=format&w=926&fit=clip'
							}
							alt='Doctor'
							width={24}
							height={24}
							unoptimized
							style={{
								width: '100%',
								height: '100%',
								objectFit: 'cover',
								objectPosition: 'center',
								borderRadius: '4px'
							}}
						/>
					</div>
					<div className={styles.order_item_info}>
						<div className='flex singleLine_ellipsis'>
							{doctor?.title?.en || 'Dr.'} {doctor?.name?.en}
							{/* <span className='ml-10'>{v1_online_rounded_icon}</span> */}
						</div>
						{/* <div className='mb-9'>Professor Mahbubul Islam Chowdhury</div> */}
						<div className='mb-2'>{doctor?.degreeNames?.map((degree) => degree).join(`, `) || 'MBBS, FCPS'}</div>
						<div>
							{doctor?.specialities?.map((specility) => specility?.name?.en).join(`, `) ||
								'Nutrition, Physical '}
						</div>
					</div>
				</div>

				<div className={styles.consultation_datetime}>
					<div>
						<AutoGraph />
					</div>
					<div className={styles.order_id_info}>
						<div>Consultation Date and Time</div>
						<div>Consultation Date and Time</div>
					</div>
				</div>

				<div className={styles.order_confirmation}>
					<div>
						<MarkEmailRead />
					</div>
					<div className={styles.order_confirmation_info}>
						<div>Confirmation will be sent to your Arogga App, SMS</div>
						<div>Check your Arogga App or Phone SMS</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default DocConsultationOrderSuccess;
