import QueryString from 'qs';

import { request } from '@labServices/apis/arogga-api';
import { IG_DrAppointmentDetails } from './types/doc-consultation/doctor-portal/type.getDrAppointmentDetails';
import { IG_DrPastConsultationDetails } from './types/doc-consultation/doctor-portal/type.getDrPastConsultationDetails';
import { IG_DrPastConsultations } from './types/doc-consultation/doctor-portal/type.getDrPastConsultations';
import { IG_DrTodaysAppointment } from './types/doc-consultation/doctor-portal/type.getDrTodaysAppointment';
import { IU_DocConsultationCancel } from './types/doc-consultation/type.cancelDocConsultation';
import { I_DoctorConsultationBody, IC_DoctorConsultation } from './types/doc-consultation/type.createDocConsultation';
import { IG_DocConsultationAppointmentCheckout } from './types/doc-consultation/type.getDocConsultationAppointmentCheckout';
import { IG_DocConsultationAppointment } from './types/doc-consultation/type.getDocConsultationAppointments';
import { I_DoctorCheckoutBody, IG_CheckoutDetails } from './types/doc-consultation/type.getDocConsultationCheckout';
import { IG_DoctorConsultationDetails } from './types/doc-consultation/type.getDocConsultationDetails';
import { IG_DocConsultationFollowupCheckout } from './types/doc-consultation/type.getDocConsultationFollowUpCheckout';
import { IG_DocConsultationFollowup } from './types/doc-consultation/type.getDocConsultationFollowups';
import { IG_DoctorConsultation } from './types/doc-consultation/type.getDocConsultationList';
import { IG_DoctorConsultationPaymentInfo } from './types/doc-consultation/type.getDocConsultationPaymentInfo';
import { IG_DocConsultationReasons } from './types/doc-consultation/type.getDocConsultationReasons';
import {
	IU_DocConsultationAdditionalInfo,
	IU_DocConsultationAdditionalInfoBody
} from './types/doc-consultation/type.updateDocConsultationAdditionalInfo';
import { I_ResponseDataArray, I_ResponseDataObj } from './types/type.doc-consultation-apis';

// ------------------ Start Doctor Consultation ------------------ //

export const getDocConsultationCheckout = (
	body: I_DoctorCheckoutBody
): Promise<I_ResponseDataObj<IG_CheckoutDetails>> => {
	const { doctorUqid, couponName } = body;
	return request({
		endpoint: `doc-consultation/api/v1/consultations/checkout`,
		method: 'POST',
		body: {
			doctorUqid,
			couponName
		}
	});
};

export const createDocConsultation = (
	body: I_DoctorConsultationBody
): Promise<I_ResponseDataObj<IC_DoctorConsultation>> => {
	const { doctorUqid, patientUqid, appointmentType, scheduleDate, scheduleTime, couponName, followUpUqid } = body;
	return request({
		endpoint: `doc-consultation/api/v1/consultations`,
		method: 'POST',
		body: {
			doctorUqid,
			patientUqid,
			appointmentType,
			// scheduleDate,
			// scheduleTime,
			couponName,
			followUpUqid
		}
	});
};

export const getDocConsultationList = (q: object): Promise<I_ResponseDataArray<IG_DoctorConsultation[]>> => {
	const qs = QueryString.stringify(q);
	return request({
		endpoint: `doc-consultation/api/v1/consultations?${qs}`,
		method: 'GET'
	});
};

export const getDocConsultationDetails = (id: string): Promise<I_ResponseDataObj<IG_DoctorConsultationDetails>> => {
	return request({
		endpoint: `doc-consultation/api/v1/consultations/${id}`,
		method: 'GET'
	});
};

export const onlinePaymentForConsultation = (
	consultationId: string
): Promise<I_ResponseDataObj<IG_DoctorConsultationPaymentInfo>> => {
	return request({
		endpoint: `doc-consultation/api/v1/consultations/${consultationId}/payment`,
		method: 'GET'
	});
};

export const getDocConsultationReasons = (): Promise<I_ResponseDataArray<IG_DocConsultationReasons[]>> => {
	return request({
		endpoint: `doc-consultation/api/v1/shared/consultation-reasons`,
		method: 'GET'
	});
};

export const getDocConsultationCancelReasons = (): Promise<I_ResponseDataArray<IG_DocConsultationReasons[]>> => {
	return request({
		endpoint: `doc-consultation/api/v1/shared/consultation-cancel-reasons`,
		method: 'GET'
	});
};

export const cancelDocConsultation = (
	consultationId: string,
	cancelReason: string
): Promise<I_ResponseDataObj<Partial<IU_DocConsultationCancel>>> => {
	return request({
		endpoint: `doc-consultation/api/v1/consultations/${consultationId}/cancellation`,
		method: 'PUT',
		body: {
			cancelReason
		}
	});
};

export const updateDocConsultationAdditionalInfo = (
	consultationId: string,
	body: IU_DocConsultationAdditionalInfoBody | FormData
): Promise<I_ResponseDataObj<IU_DocConsultationAdditionalInfo>> => {
	return request({
		endpoint: `doc-consultation/api/v1/consultations/${consultationId}`,
		method: 'PUT',
		body: body,
		customOptions: {
			isFormData: true
		}
	});
};

export const getDocConsultationFollowUps = (q: object): Promise<I_ResponseDataArray<IG_DocConsultationFollowup[]>> => {
	const qs = QueryString.stringify(q);
	return request({
		endpoint: `doc-consultation/api/v1/follow-ups?${qs}`,
		method: 'GET'
	});
};

interface I_FollowUpCheckoutBody {
	followUpId: string;
}
export const getDocConsultationFollowUpCheckout = (
	body: I_FollowUpCheckoutBody
): Promise<I_ResponseDataObj<IG_DocConsultationFollowupCheckout>> => {
	const { followUpId } = body;
	return request({
		endpoint: `doc-consultation/api/v1/follow-ups/${followUpId}/checkout`,
		method: 'GET'
	});
};

export const getDocConsultationAppointments = (
	q: object
): Promise<I_ResponseDataArray<IG_DocConsultationAppointment[]>> => {
	const qs = QueryString.stringify(q);
	return request({
		endpoint: `doc-consultation/api/v1/appointments?${qs}`,
		method: 'GET'
	});
};

interface I_AppointmentCheckoutBody {
	appointmentId: string;
}
export const getDocConsultationAppointmentCheckout = (
	body: I_AppointmentCheckoutBody
): Promise<I_ResponseDataObj<IG_DocConsultationAppointmentCheckout>> => {
	const { appointmentId } = body;
	return request({
		endpoint: `doc-consultation/api/v1/appointments/${appointmentId}/checkout`,
		method: 'GET'
	});
};

// ------------------ End Doctor Consultation ------------------ //

// ------------------ Start Doctor Portal ------------------ //
export const getDrTodaysAppointments = (): Promise<I_ResponseDataObj<IG_DrTodaysAppointment>> => {
	return request({
		endpoint: `doc-consultation/api/v1/doctor/appointment-queues/today`,
		method: 'GET'
	});
};

export const getDrAppointmentDetails = (
	appointmentUqid: string
): Promise<I_ResponseDataObj<IG_DrAppointmentDetails>> => {
	return request({
		endpoint: `doc-consultation/api/v1/doctor/appointments/${appointmentUqid}`,
		method: 'GET'
	});
};

export const getDrPastConsultations = (q?: object): Promise<I_ResponseDataArray<IG_DrPastConsultations>> => {
	const qs = QueryString.stringify(q);
	return request({
		endpoint: `doc-consultation/api/v1/doctor/consultations/past?${qs}`,
		method: 'GET'
	});
};

export const getDrPastConsultationDetails = (id: string): Promise<I_ResponseDataObj<IG_DrPastConsultationDetails>> => {
	return request({
		endpoint: `doc-consultation/api/v1/doctor/consultations/${id}`,
		method: 'GET'
	});
};

export const getDrUpComingAppointments = (): Promise<I_ResponseDataArray<IG_DrTodaysAppointment>> => {
	return request({
		endpoint: `doc-consultation/api/v1/doctor/appointment-queues`,
		method: 'GET'
	});
};

// ------------------ End Doctor Portal ------------------ //
