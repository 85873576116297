'use client';
import useOutsideClick from '@labServices/hooks/useOutsideClick';
import { useRef } from 'react';
import styles from './Popover.module.scss';

const Popover = ({ variant, open, handleClose, children, position }) => {
	const ref = useRef();

	useOutsideClick(ref, handleClose);

	return (
		<div
			ref={ref}
			className={`
		${styles.popover} 
		${variant ? styles[variant] : ''} 
		${position ? styles[position] : ''}
		${open ? styles.open : ''}`}>
			{children}
		</div>
	);
};

export default Popover;
