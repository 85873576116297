import styles from './DrSectionHeader.module.scss';

interface I_DrSectionHeaderProps {
	text: string;
	icon?: JSX.Element;
	onclick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const DrSectionHeader = ({ text, icon, onclick }: I_DrSectionHeaderProps) => {
	return (
		<div className={styles.drSectionHeader}>
			<h5 className='lth5_bold ltGreyscale900'>{text}</h5>
			{icon && (
				<div className={styles.icon} onClick={onclick}>
					{icon}
				</div>
			)}
		</div>
	);
};

export default DrSectionHeader;
