'use client';

import CouponCodeInput from '@/labtest/presentation/atoms/CouponCodeInput';
import { getDocConsultationCheckout } from '@/services/doc-consultation/apis/doc-consultation-apis/doc-consultation';
import { IG_CheckoutDetails } from '@/services/doc-consultation/apis/doc-consultation-apis/types/doc-consultation/type.getDocConsultationCheckout';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { IG_DoctorConsultationDetails } from '@/services/doc-consultation/apis/doc-consultation-apis/types/doc-consultation/type.getDocConsultationDetails';
import styles from './PromoSectionCard.module.scss';

interface I_Props {
	data: Partial<IG_DoctorConsultationDetails>;
	setCheckoutData?: (data: Partial<IG_CheckoutDetails>) => void;
}

const PromoSectionCard = ({ data, setCheckoutData }: I_Props) => {
	const { couponName } = data;
	const [couponCode, setCouponCode] = useState<string>('');
	const [isCouponCodeApplied, setIsCouponCodeApplied] = useState<boolean>(false);

	const handleCouponCode = async (code: string) => {
		const res = await getDocConsultationCheckout({
			doctorUqid: data?.doctor?.id,
			couponName: code
		});

		if (res?.success) {
			setIsCouponCodeApplied(true);
			setCheckoutData({ ...data, ...res?.data });
			// setItemInLocalStorage(cartStorageName, updatedCartData?.data);
		} else {
			setIsCouponCodeApplied(false);
			toast.error(res?.message);
		}
	};

	const handelResetCouponCode = async () => {
		setCouponCode('');
		setIsCouponCodeApplied(false);

		const res = await getDocConsultationCheckout({
			doctorUqid: data?.doctor?.id,
			couponName: ''
		});

		if (res?.success) {
			setCheckoutData({ ...data, ...res?.data });
			// setItemInLocalStorage(cartStorageName, updatedCartData?.data);
		}
	};
	return (
		<div className={styles.promoSecCard}>
			<div className='ltbody_md__medium  primaryBase text_underline  mb-15'>Have coupon code ?</div>
			<CouponCodeInput
				onClickApply={handleCouponCode}
				onClickReset={handelResetCouponCode}
				applyButtonText={couponName || isCouponCodeApplied ? 'Remove' : 'Apply'}
				isCouponCodeApplied={isCouponCodeApplied}
				couponCode={couponCode}
				setCouponCode={setCouponCode}
				componentType='drc'
			/>
		</div>
	);
};

export default PromoSectionCard;
