import styles from './Radio.module.css';

const Radio = ({ checked, onChange, name, label, btnSize, textSize, value, defaultValue, ...rest }) => {
	return (
		<label className={styles.radio_button}>
			<div className={`flex relative`}>
				<input
					type='radio'
					name={name}
					checked={checked === value || checked === label}
					value={defaultValue || value}
					onChange={onChange}
					{...rest}
				/>
				<span
					className={`
					${styles.fake_radiobutton} 
					${btnSize && styles[`fake_radiobutton_${btnSize}`]}
					`}></span>
			</div>
			<p
				className={`
				${styles.label} 
				${textSize && styles[`label_${textSize}`]}
				`}>
				{label}
			</p>
		</label>
	);
};

export default Radio;
