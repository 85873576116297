import Line from '@/labtest/presentation/atoms/Line';

import Button from '@/labtest/presentation/atoms/Button';
import { IG_CheckoutDetails } from '@/services/doc-consultation/apis/doc-consultation-apis/types/doc-consultation/type.getDocConsultationCheckout';
import { IG_DoctorConsultationDetails } from '@/services/doc-consultation/apis/doc-consultation-apis/types/doc-consultation/type.getDocConsultationDetails';
import { getFlatNumber } from '@/services/lab-test/utils/cmnHelper';
import PromoSectionCard from '../PromoSectionCard';
import styles from './PaymentDetailsCard.module.scss';

export interface I_Props {
	data: Partial<IG_DoctorConsultationDetails>;
	setCheckoutData?: (data: Partial<IG_CheckoutDetails>) => void;
	page?: string;
	followUpId: string;
}

const PaymentDetailsCard = ({ data, setCheckoutData, page, followUpId }: I_Props) => {
	return (
		<>
			<div className={styles.paymentDetailsCard}>
				{page !== 'order_success_modal' && page !== 'consultation-details' && !followUpId && (
					<PromoSectionCard data={data} setCheckoutData={setCheckoutData} />
				)}

				<div className='px-20 py-20 flex-column gap-12'>
					{/* Subtotal  */}
					<div className='flex justify-between'>
						<p className='ltbody_lg__regular ltGreyscale900'>Subtotal</p>
						<p className='ltbody_lg__regular ltGreyscale900'>৳{data?.subtotalAmount}</p>
					</div>

					{/* Discount Applied */}
					<div className='flex justify-between'>
						<p className='ltbody_lg__regular ltGreyscale700'>Discount Applied</p>
						<p className='ltbody_lg__regular'>
							{Number(data?.feeDiscountAmount) > 0
								? `-৳${getFlatNumber(Number(data?.feeDiscountAmount))}`
								: `৳${getFlatNumber(Number(data?.feeDiscountAmount))}`}
						</p>
					</div>

					{/* Coupon Applied */}
					{Number(data?.couponDiscountAmount) > 0 && data?.couponName && (
						<div className='flex justify-between'>
							<p className='ltbody_lg__regular ltGreyscale700'>
								Coupon Applied {data?.couponName && `(${data?.couponName})`}
							</p>
							<p className='ltbody_lg__regular'>-৳{getFlatNumber(Number(data?.couponDiscountAmount))}</p>
						</div>
					)}

					{/* Rounding Off */}
					{Number(data?.roundingOffAmount) !== 0 && (
						<div className='flex justify-between'>
							<p className='ltbody_lg__regular ltGreyscale700'>Rounding Off</p>
							<p className='ltbody_lg__regular'>
								{Number(data?.roundingOffAmount) > 0
									? `-৳${data?.roundingOffAmount}`
									: `৳${data?.roundingOffAmount}`}
							</p>
						</div>
					)}

					{/* Arogga Cash Applied */}
					<div className='flex justify-between'>
						<p className='ltbody_lg__regular ltGreyscale700'>Arogga Cash Applied</p>
						<p className='ltbody_lg__regular errorBase'>
							{Number(data?.totalCashUsed) == 0
								? `৳${Number(data?.totalCashUsed)}`
								: `-৳${Number(data?.totalCashUsed)}`}
						</p>
					</div>
				</div>

				<Line dashed='dashed' />

				<div className='px-20 py-20 flex-column gap-12'>
					{/* Amount Payable  */}
					<div className='flex justify-between'>
						<p className='ltbody_lg__medium  ltGreyscale900'>Amount Payable</p>
						<p className='ltbody_lg__medium  ltGreyscale900'>৳{getFlatNumber(Number(data?.totalAmount))}</p>
					</div>

					{/* You Paid  */}
					{data?.paymentStatus === 'paid' && (
						<div className='flex justify-between'>
							<p className='ltbody_lg__semibold ltGreyscale900'>
								You Paid <span className='ltbody_lg__regular ltGreyscale600'>(Online Payment)</span>
							</p>
							<p className='ltbody_lg__semibold ltGreyscale900'>৳{getFlatNumber(Number(data?.paidAmount))}</p>
						</div>
					)}

					{/* Download Invoice */}
					{data?.consultationStatus === 'completed' && (
						<Button variant='bgWhitePrimary' color='clPrimary' fullWidth>
							Download your invoice
						</Button>
					)}
				</div>
			</div>
		</>
	);
};

export default PaymentDetailsCard;
