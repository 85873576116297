import { MdCheckCircle } from 'react-icons/md';

import styles from './BookforItem.module.css';

const BookforItem = ({ onClick, checked, children }) => {
	return (
		<div onClick={onClick} className={`${styles.paitent_num} ${checked ? styles.checked : ''} ltGreyscale900`}>
			{children}
			{checked ? <MdCheckCircle size={24} color='var(--ltPrimary)' /> : ''}
		</div>
	);
};

export default BookforItem;
